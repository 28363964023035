<template>
  <div class="bg-white text-black antialiased dark:bg-zinc-900 dark:text-gray-200">
    <div class="md:container mx-auto">
      <div :class="`sticky bg-white dark:bg-zinc-900 top-0 z-[2] ${route.name === 'eventcheckin' ? 'hidden' : ''}`">
        <div class="flex items-center justify-between px-2 py-1">
          <div class="block w-24">
            <HeaderLogo />
          </div>

          <div class="hidden lg:flex">
            <nav class="flex justify-center items-end">
              <NuxtLink v-if="isInsideMyEvents || isProducer" class="flex justify-center items-center p-2" to="/meus_eventos">
                <icon-calendar class="mr-2" />
                <span class="block w-full mt-1 text-center">meus eventos</span>
              </NuxtLink>

              <!-- <NuxtLink v-if="isInsideMyEvents || isProducer" class="flex justify-center items-center p-2" to="/carteira">
                <icon-wallet class="mr-2" />
                <span class="block w-full mt-1 text-center">carteira</span>
              </NuxtLink> -->

              <NuxtLink v-if="!isInsideMyEvents" class="flex justify-center items-center p-2" to="/arquivo">
                <icon-list class="mr-2" />
                <span class="block w-full mt-1 text-center">eventos</span>
              </NuxtLink>

              <NuxtLink v-if="!isInsideMyEvents" class="flex justify-center items-center p-2" to="/como-funciona">
                <icon-help class="mr-2" />
                <span class="block w-full mt-1 text-center">como funciona</span>
              </NuxtLink>
            </nav>
            <nav id="admin-header-nav" class="flex justify-center items-end">
            </nav>
          </div>

          <div class="flex">

            <NuxtLink to="/como-funciona" class="px-2 mr-2 lg:hidden">
              <h3 class="mt-0">como</h3>
              <p class="-mt-1 text-xs">funciona</p>
            </NuxtLink>

            <HeaderProfileSwitch />
          </div>

        </div>
      </div>

      <div class="pb-24 relative z-0">
        <!-- change to slot -->

        <!-- <NuxtPage v-slot="{ Component }"> -->
          <!-- <transition
            enter-active-class="transition absolute w-full top-0 ease-out duration-300"
            enter-from-class="opacity-0"
            enter-to-class="opacity-100"
            leave-active-class="transition ease-in duration-300"
            leave-from-class="opacity-100"
            leave-to-class="opacity-0"> -->
            <!-- <component :is="Component" /> -->
            <slot />
          <!-- </transition> -->
        <!-- </NuxtPage> -->

        <footer class="text-center mt-32 mb-12">
          <img class="mx-auto w-40 dark:invert" src="/badgenew.png" alt="Pixta Badge" width="128" height="128" />
          <p class="text-sm">
            A <strong>pixta</strong> é um produto independente de Curitiba,
            <br>
            feito por pessoas que amam música e tecnologia.
          </p>

          <p class="mt-4 text-sm">
            Tem alguma dúvida?
            <br>
            Confira a <NuxtLink class="underline opacity-70" to="/central-de-ajuda">central de ajuda</NuxtLink>
          </p>

          <p class="mt-4 text-sm">
            Saiba <NuxtLink class="underline opacity-70" to="/como-funciona">como funciona</NuxtLink> e crie seu evento
          </p>
          <p class="mt-4 text-xs">
            <NuxtLink class="underline opacity-70" to="/arquivo">eventos passados</NuxtLink>
          </p>
          <!-- <a href="https://n.pixta.me">termos de uso</a>
          <a href="https://n.pixta.me">privacidade e cookies</a> -->
        </footer>
      </div>
    </div>

    <div class="fixed lg:hidden bottom-0 z-10 w-full block text-xs sm:text-base">
      <div id="admin-bottom-nav" class="bg-white text-xl flex justify-center text-black dark:bg-zinc-900 dark:text-gray-200 w-full overflow-hidden h-18">
      </div>
      <div class="bg-white text-black dark:bg-zinc-900 pb-2 dark:text-gray-200 w-full overflow-hidden h-18">
        <transition
          enter-active-class="transition ease-out duration-200 transform"
          enter-from-class="scale-y-0 opacity-0"
          enter-to-class="transform-none translate-y-0 opacity-100"
          leave-active-class="transition ease-in duration-200 transform relative"
          leave-from-class="translate-y-8"
          leave-to-class="translate-y-0 scale-y-0 opacity-0">
          <nav class="flex justify-center items-end" v-if="isInsideMyEvents">
            <NuxtLink class="flex flex-wrap justify-center items-center p-2" to="/meus_eventos">
              <icon-calendar class="block" />
              <span class="block w-full mt-1 text-center">eventos</span>
            </NuxtLink>

            <!-- <NuxtLink class="flex flex-wrap justify-center items-center p-2" to="/carteira">
              <icon-wallet class="block" />
              <span class="block w-full mt-1 text-center">carteira</span>
            </NuxtLink> -->

            <NuxtLink class="flex flex-wrap justify-center items-center p-2" to="/conta">
              <icon-user class="block" />
              <span class="block w-full mt-1 text-center">conta</span>
            </NuxtLink>
          </nav>

          <nav class="flex justify-center items-end" v-else>
            <NuxtLink class="flex flex-wrap justify-center items-center p-2" to="/">
              <icon-home class="block" />
              <span class="block w-full mt-1 text-center">home</span>
            </NuxtLink>

            <NuxtLink class="flex flex-wrap justify-center items-center p-2" to="/ingressos">
              <icon-ticket class="block" />
              <span class="block w-full mt-1 text-center">ingressos</span>
            </NuxtLink>


            <NuxtLink class="flex flex-wrap justify-center items-center p-2" to="/conta">
              <icon-user class="block" />
              <span class="block w-full mt-1 text-center">conta</span>
            </NuxtLink>
          </nav>
        </transition>
      </div>
    </div>

  </div>
</template>

<script setup>
// import UserService from "@/services/user.service"
// import HeaderLogo from '@/components/HeaderLogo.vue'
// import { mapStores } from 'pinia'
import { useUserStore } from '@/store/user.js'
import { useMainStore } from '@/store/root.js'
// import { ClientOnly } from '@/components/ClientOnly.js'

const userStore = useUserStore()
const mainStore = useMainStore()
const router = useRouter()
const route = useRoute()

import { storeToRefs } from 'pinia'
const { currentUser, loggedIn: isLoggedIn, isProducer, currentProducer } = storeToRefs(userStore)

const { isMenuOpen } = storeToRefs(mainStore)

const isInsideMyEvents = computed(() => {
  return route.path.toString().startsWith('/meus_eventos') || route.path.toString().startsWith('/carteira')
})

const isShowFooter = computed(() => {
  return !isInsideMyEvents
})

useHead({
  htmlAttrs: {
    lang: 'pt-BR',
    class: 'bg-zinc-900 light:bg-white'
  }
})
</script>

<style lang="scss">
.page-enter-active,
.page-leave-active {
  transition: all 0.25s;
}
.page-enter-from,
.page-leave-to {
  opacity: 0;
  filter: blur(1rem);
}
</style>
